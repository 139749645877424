import React from "react"
import {
  Wrapper,
  HeaderWrapper,
  StyledImg,
  ContentWrapper,
} from "./ReservationHero.styles"
import useReservationHeroQuery from "../../hooks/useReservationHeroQuery"

const ReservationHero = () => {
  const { imageData: reservationImage, content } = useReservationHeroQuery()

  return (
    <Wrapper>
      <StyledImg image={reservationImage} alt="Hero Image"></StyledImg>
      <HeaderWrapper>
        <h1> Make a reservation</h1>
      </HeaderWrapper>
      <ContentWrapper dangerouslySetInnerHTML={{ __html: content }} />
    </Wrapper>
  )
}

export default ReservationHero

import React from "react"
import Layout from "../components/Layout/Layout"
import ReservationForm from "../components/Forms/ReservationForm"
import ReservationHero from "../components/ReservationHero/ReservationHero"
import SEO from "../components/SEO/SEO"
import FiveStar from "../components/FiveStar/FiveStar"

function reservation() {
  return (
    <Layout>
      <SEO title="Reservation" />
      <ReservationHero />
      <ReservationForm />
      <FiveStar />
    </Layout>
  )
}

export default reservation

import React, { useEffect, useState } from "react"
import { useHubspotForm } from "@aaronhayes/react-use-hubspot-form"
import { StyledHubspotForm, Wrapper } from "./ReservationForm.style"

const ReservationForm = () => {
  useHubspotForm({
    portalId: "25677925",
    formId: "ce372a12-216f-4a0d-aab3-ce8d635096ba",
    target: "#my-hubspot-form",
  })

  return (
    <>
      <Wrapper>
        <StyledHubspotForm id="my-hubspot-form"></StyledHubspotForm>
      </Wrapper>
    </>
  )
}

export default ReservationForm
